@font-face {
font-family: '__sairaCondensed_20001b';
src: url(/_next/static/media/ba8bf7a7bccf3262-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 600;
font-style: semibold;
}

@font-face {
font-family: '__sairaCondensed_20001b';
src: url(/_next/static/media/2744eaa4aa9eb6c1-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: medium;
}

@font-face {
font-family: '__sairaCondensed_20001b';
src: url(/_next/static/media/80f206e5e4c5b5e5-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}@font-face {font-family: '__sairaCondensed_Fallback_20001b';src: local("Arial");ascent-override: 141.70%;descent-override: 54.81%;line-gap-override: 0.00%;size-adjust: 80.10%
}.__className_20001b {font-family: '__sairaCondensed_20001b', '__sairaCondensed_Fallback_20001b'
}.__variable_20001b {--font-saira-condensed: '__sairaCondensed_20001b', '__sairaCondensed_Fallback_20001b'
}

@font-face {
font-family: '__sairaSemiCondensed_38f392';
src: url(/_next/static/media/a5e8f2fabffecb6d-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 600;
font-style: semibold;
}

@font-face {
font-family: '__sairaSemiCondensed_38f392';
src: url(/_next/static/media/b8ef35a2b820507c-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: medium;
}

@font-face {
font-family: '__sairaSemiCondensed_38f392';
src: url(/_next/static/media/a116c82d95c77462-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}@font-face {font-family: '__sairaSemiCondensed_Fallback_38f392';src: local("Arial");ascent-override: 122.21%;descent-override: 47.27%;line-gap-override: 0.00%;size-adjust: 92.87%
}.__className_38f392 {font-family: '__sairaSemiCondensed_38f392', '__sairaSemiCondensed_Fallback_38f392'
}.__variable_38f392 {--font-saira-semi-condensed: '__sairaSemiCondensed_38f392', '__sairaSemiCondensed_Fallback_38f392'
}

@font-face {
font-family: '__saira_40bcad';
src: url(/_next/static/media/c920fa74b1fefc7d-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 600;
font-style: semibold;
}

@font-face {
font-family: '__saira_40bcad';
src: url(/_next/static/media/e0e775fc420700ef-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: medium;
}

@font-face {
font-family: '__saira_40bcad';
src: url(/_next/static/media/ea7bc52267ebfd9d-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}@font-face {font-family: '__saira_Fallback_40bcad';src: local("Arial");ascent-override: 108.27%;descent-override: 41.88%;line-gap-override: 0.00%;size-adjust: 104.83%
}.__className_40bcad {font-family: '__saira_40bcad', '__saira_Fallback_40bcad'
}.__variable_40bcad {--font-saira: '__saira_40bcad', '__saira_Fallback_40bcad'
}

